import { isLoggerUsed } from '@/helpers/javascript/LoggerHelper';

const isLoggerActive = isLoggerUsed(import.meta.env.VITE_DEBUG_STORE);

function stripReferences(data: any[]): any[] {
  return data.map((d) => (typeof d !== 'object' ? d : Object.assign({}, d)));
}

export function storeLogger(...data: any[]) {
  if (isLoggerActive) {
    data = stripReferences(data);

    console.log('%c store ', 'background: #eef280; color: #3437ed', ...data);
  }
}
