<template>
  <Modal v-if="showReauthorizeModal" v-slot="{ closeModal }" v-model:show="showReauthorizeModal" size="lg">
    <h3 id="modal-headline" class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-400">
      <slot name="title"> Connectie velopen </slot>
    </h3>
    <div class="mt-2">
      <p class="text-sm text-gray-500">
        <slot name="description"> De connectie van bizcuit is verlopen vernieuw de connectie nu </slot>
      </p>
    </div>

    <div v-if="company" class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <VastgoedButton :text="$t('components.bizcuit.renew')" :color="ButtonColor.GREEN" :size="ButtonSize.MD" @click.prevent="authorizeBizcuit(company, false)" />
      <VastgoedButton :text="$t('components.bizcuit.cancel')" :color="ButtonColor.WHITE" :size="ButtonSize.MD" class="mx-3" @click="closeModal" />
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { PublicCompanyData } from '@/store/modules/company';

import Modal from '@/wrappers/Modal.vue';

import { showReauthorizeModal } from '../repository/BizcuitAuthRepository';
import { authorizeBizcuit } from '../repository/BizcuitAuthRepository';

defineProps<{ company?: PublicCompanyData | null }>();
</script>
